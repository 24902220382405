@import "../../assets/styles/scss/variables";

.bloc-image-link {
  display: grid;
  grid-template-columns: 27.65rem auto;
  background-color: $color-gray;
  width: 80%;

  .left {

    img {
      height: 100%;
      object-fit: cover;
      display: flex;
      width: 100%;
    }

    @media (max-width: 1399px) {
      width: 100%;
    }

    @media (min-width: 1199px) {
      border-radius: 0;
    }
  }

  .right {
    padding: 3.5rem 5.75rem 3.6rem 5.45rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: $color-gray;

    .title {
      padding: 0 0 2.05rem;
    }

    .btn-secondary {
      padding: 1.5rem 0 0;
    }

    @media (max-width: 1199px) {
      padding: 1.5rem;
      margin-top: -5.15rem;
      width: 90%;
    }
  }

  &.no-img {
    display: flex;
    .right {
      margin: 0;
      width: 100%;
    }

    @media (max-width: 1199px) {
      display: flex;
      flex-direction: column;
      width: 90%;
      
      .right {
        margin: 0;
      }
    }
  }

  @media (max-width: 1599px) {
    width: 100%;
  }

  @media (max-width: 1199px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
  }

}