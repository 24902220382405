@import "../../assets/styles/scss/variables";

.standard-header {
  padding-top: $headerHeight;

  .inner-container, .container {

    .flex {
      flex-direction: column;
      width: 70%;
      padding: 6.9rem 0 5rem;

      h1 {
        padding: 0 0 1.5rem;
      }

      h2.large {
        margin-bottom: 1.5rem;
      }
    }

    .grid {
      display: grid;
      column-gap: 4rem;
      grid-template-columns: calc(60% - 2rem) calc(40% - 2rem);
      padding-top: 2rem;

      .left {
        display: flex;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      .right {
        padding: 0 10% 2.5rem 0;

        &.no-icon {
          padding: 3rem 10% 2.5rem 0;
        }

        .profil-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 2.2rem;
          height: 2.2rem;
          border-radius: $rounded;
          border: solid $color-primary 1px;
          padding: 0.25rem;
          margin-bottom: 0.45rem;
        }

        h2.large {
          padding: 1.67rem 0 4.25rem;
        }

        .press-contact {
          margin-top: 2.22rem;
          width: 70%;
          display: flex;
          flex-direction: column;
          padding: 2.22rem;
          background-color: $color-primary;
          border-radius: $rounded;

          .press-name {
            display: flex;
            gap: 5px;
            color: white;
            font-weight: 500;
            font-size: 1.111rem;

            &::before {
              content: url(../../assets/images/card_spv_member.svg);

              svg {
                width: 1rem;
                height: 1rem;
              }
            }
          }
        }

        .anchors-list {
          list-style-type: none;
          margin: 0;
          padding: 0;

          .anchor {
            width: 100%;
            border-bottom: solid 1px rgba(214, 11, 82, 0.1);

            .anchor-link {
              color: $color-primary;
              padding: 0.56rem 0;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;

              &:after {
                content: url('../../assets/images/arrow.svg');
                width: $xsmall;
                height: $xsmall;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all .2s linear;
                transform: rotate(0deg);
                transform-origin: center;
              }
            }

            &:hover {
              .anchor-link {
                padding-left: $xxsmall;

                &:after {
                  transform-origin: center;
                  transform: rotate(90deg);
                }
              }
            
            }

            &:last-child {
              border-bottom: 0 ;
            }
          }
        }
      }
    }
  }

  @media(max-width: 1399px) {
    .inner-container, .container {
      .grid {

        .right {
          .press-contact {
            width: 100%;
          }
        }
      }
    }
  }

  @media(max-width: 991px) {
    .inner-container, .container {
      .grid {
        padding: 0;
        display: flex;
        flex-direction: column;

        .right {
          padding: 0;

          &.no-icon {
            padding: 0;
          }

          .press-contact {
            width: 70%;
          }

          h1 {
            padding: 0.9rem 0;
          }

          h3 {
            padding: 0.9rem 0 2.36rem;
          }
        }
      }
    }
  }

  @media(max-width: 767px) {
    .inner-container, .container {
      .grid {
        .right {
          .press-contact {
            width: 100%;
          }
        }
      }
    }
  }
}