@import "../../assets/styles/scss/variables";

#search-post {
  width: 100%;
  min-width: calc(80vw / 4);
  
  .input-group {
    position: relative;
    display: flex;
    align-items: center;

    input {
      background-color: white;
      border-radius: $rounded;
      padding: .8rem 1.25rem .8rem 2.95rem;
      font-size: $regular;
      line-height: normal;

      &::placeholder {
        font-size: 0.85rem;
        font-weight: 300;
        line-height: 1.6rem;
        opacity: .4;
      }
    }

  }

  i {
    position: absolute;
    left: 1.25rem;
    color: $color-primary;
  }
}