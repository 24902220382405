@import "../../assets/styles/scss/variables";

.bloc-related-event {
  display: grid;
  grid-template-columns: 25% auto;
  color: black;
  position: relative;

  svg {
    position: absolute;
    right: 1.5rem;
    bottom: 1.5rem;
    width: $xsmall;
    height: auto;
    
    path {
      transition: all .2s linear;
    }
  }

  .date {
    color: $color-primary;
    font-size: 2.7rem;
    line-height: normal;
    font-weight: 400;
    padding: $massive;
    border-right: 1px solid #E9E9E9;
    transition: all .2s linear;
    white-space: nowrap;

    span.year {
      margin-top: .4rem;
      display: block;
      font-size: 1.7rem;
      line-height: normal;
      white-space: nowrap;
    }
  }

  .content {
    padding: $massive 4rem;
    padding-right: 10rem;

    h2 {
      color: $color-gray;
    }

    p {
      margin-top: .6rem;
    }
  }

  &:hover {
    color: white;
    background-color: $color-primary;

    .date {
      color: inherit;
    }

    svg {

      path {
        fill: white;
      }
    }
  }

  @media (max-width:1441px) {

    .date {
      padding: 2rem;
    }

    .content {
      padding: 2rem $massive;
      padding-right: $gigantic;
    }
  }

  @media (max-width:991px) {
    padding: 1.5rem;
    padding-bottom: 3.8rem;
    display: flex;
    flex-direction: column;

    .date {
      padding: 0;
      padding-bottom: 1.5rem;
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      gap: 1rem;
      border-right: none;
      border-bottom: 1px solid #E9E9E9;
      font-size: 2.2rem;

      span.year {
        display: inline;
      }
    }

    .content {
      padding: 0;
      padding-top: 1.5rem;
    }
  }
}