@import "../../assets/styles/scss/variables";

.icon-card {
    background-color: $color-gray;
    padding: 1.5rem 4.2rem 1.75rem 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.6rem;

    .icon {
        width: 3.8rem;
        height: 3.8rem;
        border-radius: 0.83333rem;
        border: solid 1px $color-primary;
        padding: 0.22rem;
    }
}