@import "../../assets/styles/scss/variables";

.accordion-infos, .accordion-infos-open {
  position: relative;
  padding-right: 4.5rem;

  &::after {
    content:"";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: $color-primary;
    opacity: .3;
  }

  &:last-child {
    &::after {
      display: none;
    }
  }

  .accordion-title {
    padding: 1.17rem 0;
    display: flex;
    align-items: center;
    gap: $small;
    cursor: pointer;
    justify-content: flex-end;
    flex-direction: row-reverse;

    .icon {
      border-radius: 100%;
      width: 1.4rem;
      height: 1.4rem;
      aspect-ratio: 1;
      min-width: 1.4rem;
      min-height: 1.4rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        transform: rotate(90deg);
        transform-origin: center;
        transition: all 0.3s ease;
        filter: invert(1);
    }
    }

    &.is-open {
      img {
        margin-top: -2px;
        transform: rotate(270deg);
        transition: all 0.3s ease;
      }
    }
  }

  .accordion-content {
    display: none;
    padding: 0 0 1.5rem 2.5rem;

    ul {
      padding-left: 20px;
    }
  }
}

.accordion-infos-open {

  .accordion-title {
    cursor: auto;
    padding: 0.8rem 0 1.17rem;

    .icon {

      img {
        transform: rotate(0deg);
    }
    }

    &.is-open {
      img {
        transform: rotate(0deg);
      }
    }
  }

  .accordion-content {
    display: flex;
  }

  &::after {
    content:"";
    height: 1px;
    background-color: $color-primary;
    opacity: 0;
  }
}

@media (max-width: 1199px) {
  .accordion-infos, .accordion-infos-open {
    padding-right: 0;
  }
}