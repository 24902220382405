@import "../../assets/styles/scss/variables";

.adapted-services-section {
    margin-top: 7.78rem;

    .section-image {
        display: flex;
        height: 37rem;
        width: 100%;
        margin-bottom: -21rem;

        img {
            width: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    .section-title {
        margin-left: auto;
    }

    .section-content {
        .left-content {
            background-color: white;
            z-index: 10;
            width: 70%;
            padding: 2.78rem; 
            position: relative;
        }
    }

    @media (max-width: 991px) {

        .section-content {
            .left-content {
                width: 100%;
            }
        }
    }
}