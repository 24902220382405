@import "../../assets/styles/scss/variables";

.ts-wrapper {
  width: 100%;
  min-width: 15vw;

  .ts-control {
    background-color: white;
    border-radius: $rounded;
    justify-content: flex-start;
    padding: .8rem 1.25rem;
    line-height: 160%;

    .item {
      text-transform: none;
      font-size: $regular;
    }

    &:after {
      content: url('../../assets/images/arrow-select.svg');
      transform-origin: center;
    }
  }

  .ts-dropdown {
    border-radius: 0 0 $rounded $rounded;
    background-color: white;
  }

  &.dropdown-active {
    .ts-control {
      &:after {
        transform-origin: center;
      }
    }
  }
}