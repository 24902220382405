@import "../../assets/styles/scss/variables";

.bloc-resource {
  padding: 1.9rem 1.5rem;
  padding-bottom: 1rem;
  color: black;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: $gap;

  p.descr {
    margin-top: .3rem;
  }

  .infos {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    p.date {
      width: 100%;
      text-align: right;
      margin-top: 1rem;
      color: $color-gray-dark;
    }

    .types {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;
      gap: .5rem;
    }

    .btn-icon {
      text-transform: uppercase;
      margin-left: 2.2rem;
    }
  }

  &:hover {
    color: white;
    background-color: $color-primary;

    p.date {
      color: inherit;
    }

    .btn-icon {
      background-color: white;
      color: $color-primary;
    }
  }

  @media (max-width:767px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .infos {
      width: 100%;
      flex-direction: column;
      align-items: flex-end;
      gap: 1rem;

      .types {
        width: 100%;
        justify-content: flex-start;
      }
    }
  }
}
