@import "../../assets/styles/scss/variables";

.organization {
    background-color: $color-gray;
    padding: 1.5rem 4.2rem 1.75rem 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.6rem;

    span {
        color: black;
    }

    &:hover {
        background-color: $color-primary;

        span, h4 {
            color: white;
        }
    }
}