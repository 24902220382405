@import "../../assets/styles/scss/variables";

section#related-events {
  margin: 7rem 0;

  @media (max-width:991px) {
    margin: 4rem 0;
  }

  .grid {
    grid-template-columns: 23rem auto;
    gap: 3rem;

    @media (max-width:1441px) {
      grid-template-columns: 15rem auto;
    }

    @media (max-width:991px) {
      display: grid;
      grid-template-columns: 1fr;
      gap: 2rem;
    }
  }

  .related-events {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
}
