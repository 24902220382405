@import "../../assets/styles/scss/variables";

#page-header.header-img {
  margin-top: $headerHeight;
  margin-bottom: 6rem;

  @media (max-width:767px) {
    margin-top: $headerPhone;
    margin-bottom: 3rem;
  }
  
  .img-container {
    width: 100%;
    height: 70vh;
    overflow: hidden;

    img {
      width: 100%;
      height: calc(100% + 100px);
      object-fit: cover;
      object-position: center -100px;
    }
  }

  .subnav-btn {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: .5rem;
    display: none;
    font-size: 1.1rem;
    line-height: normal;
    font-weight: 500;
    background-color: $color-primary;
    color: white;
    width: 100%;
    cursor: pointer;
    margin-top: -1.6rem;
    margin-bottom: 1.5rem;

    &:hover {
      background-color: $color-primary-dark;
    }

    @media (max-width:1199px) {
      display: flex;

      &.is-active {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  .subnav {
    padding: 0;
    margin: 0;
    list-style: none;
    margin-bottom: 6.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin-inline: auto;
    margin-top: -2.1rem;

    @media (max-width:1199px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      width: 100%;
      display: none;
      position: absolute;
      left: 0;
      top: 3.3rem;

      &.is-open {
        display: flex;
      }

      li {
        width: 100%;
      }
    }

    li {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        width: 1px;
        height: 100%;
        background-color: white;
        opacity: .5;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }

      a {
        padding: 1.3rem 2.5rem;
        background-color: $color-primary;
        color: white;
        display: inline-block;
        width: 100%;

        &.current-page,
        &:hover {
          background-color: $color-primary-dark;
        }

        @media (max-width:1199px) {
          font-size: 1.1rem;
          line-height: normal;
          padding: 1rem;
        }
      }
    }
  }

  .content {
    grid-template-columns: 50% 33%;
    justify-content: space-between;

    @media (max-width:1199px) {
      grid-template-columns: repeat(2, 1fr);
      gap: $gap;
    }

    @media (max-width:767px) {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }
  }

  h1 {
    margin-bottom: 3rem;
  }

  h2.large {
    margin-bottom: 2rem;
  }

  .right {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    margin-right: -4.5%;

    @media (max-width:767px) {
      margin-right: 0;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
}

body.single-organization {

  #page-header.header-img {

    .right {
      .logo {
        max-width: 7rem;
        height: auto;
        margin-bottom: 1.5rem;
        margin-right: auto;
      }

      .contact {
        background-color: $color-primary;
        color: white;
        padding: 2rem;

        address {
          margin-bottom: 1.5rem;
        }

        p.name {
          margin: 0;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: .5rem;

          &::before {
            content: url("../../assets/images/card_spv_member.svg");
            width: 1.5rem;
            height: auto;
          }
        }

        ul.links {
          padding: 0;
          margin: 0;
          margin-top: 3rem;
          list-style: none;
          display: flex;
          flex-wrap: wrap;
          gap: .8rem;
        }
      }
    }
  }
}