@import "../../assets/styles/scss/variables";

.bloc-file {
  padding: 1rem;
  color: black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: .5rem;

  span {
    text-transform: uppercase;
    color: $color-primary;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
  }

  &:hover {
    background-color: $color-primary;
    color: white;

    span {
      color: inherit;
    }
  }
}