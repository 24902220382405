@import "../../assets/styles/scss/variables";


.bloc-profil {
  padding: 2rem;
  color: black;

  @media (max-width:767px) {
    padding: .8rem;
  }

  .icon {
    width: 2.5rem;
    min-width: 2.5rem;
    height: 2.5rem;
    min-height: 2.5rem;
    border: 1px solid $color-primary;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: .7rem;

    svg {
      width: 2rem;
      height: auto;
    }

    @media (max-width:767px) {
      width: 1.9rem;
      min-width: 1.9rem;
      height: 1.9rem;
      min-height: 1.9rem;

      svg {
        width: 1.6rem;
      }
    }
  }

  &:hover {
    background-color: $color-primary;
    color: white;

    .icon {
      border-color: white;

      img {
        filter: brightness(0) contrast(1) invert(1);
      }

      svg {
        path {
          stroke: white;
        }
      }
    }
  }
}