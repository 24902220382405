//
// Template Styles
// --------------------------------------------------
body.error404 {

    h1 {
        margin-top: 7rem;

        @media (max-width:991px) {
            margin-top: 4rem;
        }
    }
}

#main {
    z-index: 15;
}

.page-content {
    max-width: 66%;

    @media (max-width:1360px) {
        max-width: 83%;
    }

    @media (max-width:991px) {
        max-width: 100%;
    }
}

#single-organization {

    .accordions {
        max-width: 66%;
        padding: 2.5rem;
        margin-top: -1.5rem;
        margin-bottom: 6rem;

        @media (max-width:991px) {
            margin-bottom: 4rem;
        }

        @media (max-width:1199px) {
            max-width: 83%;
        }

        @media (max-width:991px) {
            max-width: 100%;
        }

        @media (max-width:767px) {
            margin-top: 0;
        }
    }
}

#page-homepage {

    section#intro {

        .top-part {
            margin-bottom: 2.5rem;

            .grid {
                grid-template-columns: 45% 40%;

                @media (max-width:1199px) {
                    grid-template-columns: repeat(2, 1fr);
                }
            }
        }

        .inner-container {
            position: relative;

            .img-container {
                position: absolute;
                top: 0;
                z-index: -1;
                width: 100%;
                opacity: 0;
                transition: all .2s linear;

                &.is-active {
                    position: relative;
                    opacity: 1;
                    z-index: 1;
                }
            }

            img {
                width: 100%;
            }
        }

        .blocs {
            padding: 0 4rem;
            grid-template-columns: repeat(3, 1fr);
            margin-top: -6rem;
            position: relative;
            z-index: 1;

            @media (max-width:1199px) {
                padding: 0;
            }

            @media (max-width:991px) {
                grid-template-columns: repeat(2, 1fr);
                margin-top: -4rem;
            }

            @media (max-width:767px) {
                margin-top: -2rem;
            }
        }

        .bloc-intro {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            padding: 3.8rem 2.5rem;
            padding-right: 4rem;
            color: $color-text;

            @media (max-width:767px) {
                padding: 1.5rem;
                padding-right: 2rem;
            }

            .icon {
                width: 2.3rem;
                min-width: 2.3rem;
                height: 2.3rem;
                min-height: 2.3rem;
                border: 1px solid $color-primary;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    width: 1.7rem;
                    height: auto;
                    transition: all .2s linear;
                }
            }

            &:hover,
            &.is-active {
                color: white;
                background-color: $color-primary;

                .icon {
                    border-color: white;

                    img {
                        filter: brightness(0) contrast(1) invert(1);
                    }

                    svg {
                        path {
                            stroke: white;
                        }
                    }
                }
            }
        }
    }

    section#profiles {
        margin: 7rem 0;

        @media (max-width:991px) {
            margin: 4rem 0;
        }

        .grid {
            gap: $gap;
            grid-template-columns: auto 66%;

            @media (max-width:991px) {
                display: flex;
                flex-direction: column;
            }
        }

        .profiles {
            grid-template-columns: repeat(3, 1fr);

            @media (max-width:1260px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media (max-width:991px) {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
            }

            @media (max-width:767px) {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }

    @media (max-width:767px) {
        margin-top: 13rem;
    }
}

#page-agenda-archive {
    margin-top: 5rem;
    margin-bottom: 7rem;

    @media (max-width:991px) {
        margin-top: 3rem;
        margin-bottom: 4rem;
    }

    .events {
        gap: 2rem;
        grid-template-columns: repeat(2, 1fr);

        @media (max-width:1441px) {
            display: flex;
            flex-direction: column;
        }

        .bloc-event {

            .date {
                padding: 2rem;

                @media (max-width:991px) {
                    padding: 0;
                    padding-bottom: 1.5rem;
                }
            }

            .content {
                padding: 2rem 1.5rem;
                padding-right: 4rem;

                @media (max-width:991px) {
                    padding: 0;
                    padding-top: 1.5rem;
                }
            }
        }
    }

    .btn-primary.load-more {
        margin-inline: auto;
        margin-top: 4rem;
    }
}

#page-agenda {
    margin: 7rem 0;

    @media (max-width:991px) {
        margin: 4rem 0;
    }

    .events {
        gap: 2rem;
    }

    .btn-primary {
        margin-top: 3rem;
    }
}

#page-magazins {
    margin-bottom: 7rem;

    @media (max-width:991px) {
        margin-bottom: 4rem;
    }

    h3.huge {
        margin-bottom: 5rem;
    }

    .fitlers-container {
        width: 50%;

        @media (max-width:991px) {
            width: 100%;
        }
    }

    .majuscules {
        grid-template-columns: repeat(4, 1fr);
        margin-top: 2rem;
        margin-bottom: 10rem;

        @media (max-width:1199px) {
            grid-template-columns: repeat(3, 1fr);
        }

        @media (max-width:767px) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }
    }

    #abo {
        padding: 2rem;

        h2 {
            margin-bottom: 4rem;

            @media (max-width:991px) {
                margin-bottom: 2rem;
            }
        }

        .grid {
            grid-template-columns: 41% auto;
            gap: 4rem;

            @media (max-width:1199px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media (max-width:991px) {
                display: flex;
                flex-direction: column;
                gap: 2rem;
            }
        }

        p {
            color: black;
        }

        .release,
        .price {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: .5rem;

            svg {
                width: 1.4rem;
                height: auto;
            }
        }

        .xxsmall {
            color: $color-text;
            width: 100%;
        }

        .descr {
            margin-bottom: 2rem;
        }
    }
}

#page-press {
    margin: 7rem 0;

    @media (max-width:991px) {
        margin: 4rem 0;
    }

    .files {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: $gap;
    }

    .blocs {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        gap: 2rem;

        @media (max-width:991px) {
            flex-direction: column;
            gap: 4rem;
        }

        section {
            width: 33%;

            @media (max-width:1260px) {
                width: 50%;
            }

            @media (max-width:991px) {
                width: 100%;
            }
        }
    }
}

#page-search {
    margin: 7rem 0;

    @media (max-width: 1199px) {
        margin: 5rem 0;
    }

    @media (max-width:991px) {
        margin-bottom: 4rem;
    }


    h1 {
        margin-bottom: 5rem;

        span {
            color: $color-primary;
        }
    }

    form {
        position: relative;

        button {
            position: absolute;
            top: 50%;
            right: 1.1rem;
            transform: translateY(-50%);
            padding: 0;
            background-color: transparent;
            color: $color-primary;
        }
    }

    .grid {
        gap: 2rem;
    }

    .results {
        margin-top: 2rem;
    }

    .bloc-result {
        padding: 2rem;
        min-height: 14rem;
        display: flex;
        flex-direction: column;

        h2 {
            margin-bottom: .5rem;
        }

        .btn-primary {
            margin-top: auto;
            margin-left: auto;
        }
    }
}

#page-form {
    margin: 7rem 0;

    @media (max-width:991px) {
        margin: 4rem 0;
    }

    .grid {
        grid-template-columns: 39% 50%;
        justify-content: space-between;

        .left {
            h1 {
                padding: 0 0 50px;
            }
        }

        @media (max-width:1199px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width:991px) {
            display: flex;
            flex-direction: column;
        }

        .right {

            h5 {
                margin-top: 65px;
            }

            textarea {
                max-height: 173px;
            }

            .gpnf-nested-entries-container {

                button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: .5rem;
                    width: fit-content;
                    font-size: $regular;
                    line-height: normal;
                    font-weight: 500;
                    color: #fff;
                    background-color: $color-primary;
                    padding: .4rem 1rem;
                    border-radius: $rounded;
                    transition: all .2s linear;
                    cursor: pointer; 

                    &:hover {
                        background-color: $color-primary-dark;
                        color: #fff;
                        transition: all .2s linear;
                    }
                }

                table {
                    border: 1px solid $color-primary;
                    border-radius: 1rem;
                    min-width: 100%;
                    margin-top: 0;
                    overflow: hidden;
    
                    thead {
    
                        tr {
    
                            .gpnf-field-6 {
                                border-radius: 1rem 0 0;
                            }
    
                            .gpnf-row-actions {
                                border-radius: 0 1rem 0 0;
                            }
    
                            th {
                                color: $color-primary;
                            }
                        }
                    }

                    tbody {

                        ul {
                            display: flex;
                            flex-direction: row;
                            gap: 12px;

                            li {

                                button {
                                    text-decoration: none;
                                }
                            }
                        }

                        @media (max-width: 1024px) {
                            tr {
                                margin-bottom: 3rem;

                                &:first-child {
                                    border-top: none !important;
                                }

                                td.gpnf-field {
                                    display: flex;

                                    &:first-child {
                                        border-top: none !important;
                                    }
                                    
                                    &::before {
                                        position: relative;
                                    }
                                }
                            }
                        }

                        @media ( max-width: 767px ) {
                            tr {
                              td.gpnf-field {
                                flex-direction: column;
                                align-items: flex-start;
                                
                                &:before {
                                    width: 100%;
                                    white-space: nowrap;
                                    font-weight: 700;
                                    text-overflow: ellipsis;
                                    left: 0;

                                }
                              }  
                            }
                        }
                    }
                }
            }
        }
    }
}

#page-contact {
    margin: 7rem 0;

    @media (max-width: 1199px) {
        margin: 5rem 0;
    }

    @media (max-width:991px) {
        margin-bottom: 4rem;
    }

    .highlight-blocs {
        grid-template-columns: repeat(4, 1fr);
        margin-bottom: 7rem;

        @media (max-width:991px) {
            margin-bottom: 4rem;
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width:576px) {
            display: flex;
            flex-direction: column;
        }
    }

    .contact-bloc {
        padding: 2rem;
        color: white;

        address {
            font-weight: 500;
            margin-bottom: .3rem;
        }

        .buttons {
            margin-top: 4.2rem;
            display: flex;
            gap: 1rem;
            flex-wrap: wrap;
        }
    }

    h3.huge {
        margin-bottom: 2rem;
    }

    .contact-section {

        .grid {
            display: flex;
            align-items: flex-start;
            gap: 8.3rem;

            .form-wrapper {
                width: 50%;
            }

            .contact-bloc {
                width: 33%;
            }

            @media (max-width:1199px) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 2rem;

                >div {
                    width: 100% !important;
                }
            }

            @media (max-width:991px) {
                display: flex;
                flex-direction: column;
            }
        }
    }
}

#page-manual {
    margin-bottom: 7rem;

    .container {
        display: flex;
        flex-direction: column;
    }

    @media (max-width: 991px) {
        margin-bottom: 5rem;

        .container {
            padding-top: 5rem;
        }
    }
}

#page-faq {
    .faq-content {
        margin: 0 0 5.5rem 0;
        width: 55%;

        @media (max-width: 991px) {
            width: 100%;
        }
    }
}

#page-resources {
    margin: 7rem 0;

    @media (max-width: 1199px) {
        margin: 5rem 0;
    }
}

#page-blog {
    margin: 7rem 0;

    #blog-loader {
        padding: 0 0 2rem;

        .loader, svg {
            width: 120px;
        }
    }

    .blogs {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        row-gap: 2rem;
        column-gap: 1.4rem;

        @media (min-width: 1600px) {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    @media (max-width: 1199px) {
        margin: 5rem 0;

        .blogs {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media (max-width: 767px) {
        .blogs {
            display: flex;
            flex-direction: column;
        }
    }
}

#page-about {

    .team-content {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 2rem 1.45rem;
        margin-bottom: 7rem;

        @media (max-width: 1399px) {
            grid-template-columns: repeat(3, 1fr);
        }

        @media (max-width: 991px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 576px) {
            display: flex;
            flex-direction: column;
        }
    }
}

#page-organizations {

    .organizations-content {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1.5rem;
        margin: 0 0 7rem 0;

        @media (max-width: 1399px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 576px) {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}

#page-blocs {

    .blocs {
        margin: 0 0 7rem 0;
        display: flex;
        flex-direction: column;
        gap: 5rem;

        @media (max-width: 1399px) {
            width: 100%;
        }

        @media (max-width: 1199px) {
            margin: 0 0 7rem 0;
            align-items: center;
        }
    }
}

#page-member {
    margin-bottom: 7rem;

    .membership {
        margin-top: 7rem;

        .section-title {
            margin-left: 0;
        }

        .grid {
            grid-template-columns: 41% auto;
            gap: 4rem;
            padding: 2.22rem 2.44rem;

            .left {
                display: flex;
                flex-direction: column;
                gap: 2.22rem;

                .ressources {
                    .bloc-file {
                        background-color: white;
                    }
                }
            }

            @media (max-width:1199px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media (max-width:991px) {
                display: flex;
                flex-direction: column;
                gap: 2rem;
            }
        }

        p {
            color: black;
        }
    }
}

#single-profil {
    margin-bottom: 7rem;

    #profil-advantage {
        margin-top: 7rem;

        .section-title {
            margin-left: 0;
        }

        .grid {
            margin-top: 2.78rem;
            display: grid;
            gap: 1.72rem;
            grid-template-columns: repeat(3, 1fr);
        }
    }

    #profil-faq {
        margin-top: 7rem;
        padding-bottom: 8.76rem;

        .section-image {
            display: flex;
            height: 37rem;
            width: 100%;

            img {
                width: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        .faq-fluid-container {
            position: relative;
        }

        .section-content {
            display: grid;
            grid-template-columns: 60% 40%;
            align-items: flex-start;
            max-width: 91%;
            margin: 0 4.5% 0 4.5%;
            margin-top: -8.67rem;

            .left-content {
                background-color: white;
                z-index: 10;
                width: 100%;
                padding: 1.28rem 2.78rem;
                position: relative;
            }

            .right-content {
                display: flex;
                justify-content: flex-end;
                position: relative;
            }
        }
    }

    #profil-mag, #profil-news {
        margin-top: 7rem;

        .grid {
            grid-template-columns: calc(30% - 2.61rem) calc(70% - 2.61rem);
            gap: 5.22rem;

            .right {
                padding-top: .89rem;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                row-gap: 1.67rem;
                column-gap: 1.72rem;

                .bloc-majuscules {
                    display: flex !important;
                    animation: none !important;
                }
            }
        }
    }

    @media (max-width:991px) {
        #profil-advantage {
            .grid {
                display: flex;
                flex-direction: column;
            }
        }

        #profil-faq {
            padding-bottom: 0;

            .section-content {
                display: flex;
                gap: 2.67rem;
                flex-direction: column;
                bottom: 0;
                margin-top: -4rem;
                align-items: center;

                .right-content {
                    width: 100%;

                    #quick-access {
                        width: 100%;
                    }
                }
            }
        }

        #profil-mag {
            .grid {
                display: flex;
                gap: 2.67rem;
                flex-direction: column;
            }
        }
    }

    @media (max-width:767px) {
        #profil-mag {
            .grid {
                .right {
                    grid-template-columns: repeat(2, 1fr);
                }
            }
        }
    }

    @media (max-width:399px) {
        #profil-mag {
            .grid {
                .right {
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }
}