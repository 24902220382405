//
// General Styles
// --------------------------------------------------

html {
	margin: 0;
	padding: 0;
	text-size-adjust: 100%;
	overflow-x: hidden !important;
	scroll-behavior: smooth;
}

body {
	font-family: $font-base;
	font-size: $text-base-size;
	line-height: $text-base-line-height;
	color: $color-text;
	background-color: #ffffff;
	margin: 0;
	padding: 0;
	overflow-x: hidden !important;
	scroll-behavior: smooth;
}

#main, #page-header {
	transition: all .2s ease;
}

* {
	box-sizing: border-box;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

iframe {
	max-width: 100%;
}

a img,
img {
	border: none;
	max-width: 100%;
	height: auto;
}

a {
	color: $color-link;
	cursor: pointer;
	text-decoration: none;
	transition: all .2s linear;

	&:hover {
		outline: none;
		color: $color-link-hover;
		text-decoration: none;

		i {
			color: inherit;
		}
	}

	&:focus {
		outline: none;
		text-decoration: none;
	}
}

p,
blockquote {
	margin-bottom: 20px;

	&:last-child {
		margin-bottom: 0;
	}
}

blockquote {
	border-left: 5px solid $color-gray-lighter;
	padding: 10px 0 10px 25px;
}

table {
	margin-top: $margin-base;
	margin-bottom: 15px;
	border: inherit;
	border-color: inherit;
	border-spacing: 0;
	border-collapse: collapse;

	tr {
		border: inherit;
		border-color: inherit;

		td {
			border-top: 1px solid $color-gray-lighter !important;
			padding: 1rem !important;
			vertical-align: middle !important;
		}
	}
}

address {
	font-style: normal;
}

.gform_wrapper {

	.gform_required_legend {
		display: none;
		visibility: hidden;
	}
}

.gform_validation_errors {
	padding: 0;
	color: $color-red;
	margin-bottom: 25px;
	font-size: 14px;
	display: none;

	.gform_submission_error {
		color: $color-red;
		font-size: 24px;
		line-height: normal;
		margin-bottom: 10px;
	}

	ol {
		padding: 0;
		list-style-position: inside;
	}

	.gform_validation_error_link {
		color: $color-red;
	}
}

.validation_message {
	color: $color-red;
	clear: both;
}

form {
	.gform_body {
		display: table;
		width: 100%;
	}

	.gform_footer {
		margin-top: 20px;
		display: flex;
		align-items: flex-start;
		justify-content: flex-end;
	}

	.gform_hidden {
		display: none !important;
	}

	.gfield_required {
		margin-left: 5px;
		color: $color-red;
		font-weight: bold;
		transition: all .2s linear;
	}

	.gform_fields {
		margin: 0;
		padding: 0;
		list-style: none;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		column-gap: $gap;
		row-gap: $gap;

		@media (max-width:767px) {
			display: flex;
			flex-direction: column;
		}

		.gfield_list_group {

			.gfield_list_icons {
				margin-top: 10px;
			}
		}

		.gfield {
			margin: 0;
			padding: 0;
			position: relative;
			font-size: 18px;
			line-height: normal;

			&.gfield--width-full {
				grid-column: span 2;
			}

			&.gfield_visibility_hidden {
				display: none;
			}

			&.hidden_label {
				.gfield_label {
					display: none;
				}
			}

			&.field_sublabel_above {
				margin-bottom: 20px;
			}

			&.file-input {
				display: none;
			}

			&.gfield_error {

				input,
				textarea {
					background-color: lighten($color-red, 40%);
					border: 1px solid $color-red !important;
				}

				.ts-wrapper {

					.ts-control {
						background-color: lighten($color-red, 40%);
						border: 1px solid $color-red;
					}

					input {
						border: 0 !important;
					}
				}
			}

			.gfield_label_before_complex {
				display: none;
			}

			.ginput_complex {
				display: inline-block;
				width: 100%;

				span {
					display: inline-block;
					width: 100%;

					&.name_first,
					&.ginput_left {
						width: 50%;
						padding-right: 15px;
						float: left;
					}

					&.name_last,
					&.ginput_right {
						width: 50%;
						padding-left: 15px;
						float: left;
					}

					&.address_line_1 {
						margin-bottom: 20px;
					}
				}
			}

			.gchoice {
				width: fit-content;
				transition: all .2s linear;

				&:not(:last-child) {
					margin-bottom: 4px;
				}

				input {
					visibility: hidden;
					margin: 0;
					margin-right: 0;
				}

				label {
					padding-left: 5px;
					font-weight: 400;
					position: relative;
					cursor: pointer;
					left: initial;
					top: initial;
					transition: all .2s linear;
					display: initial;

					&::before {
						position: absolute;
						top: 50%;
						left: -20px;
						transform: translateY(-50%);
						content: "";
						width: 16px;
						height: 16px;
						border-radius: 50%;
						border: 2px solid $color-primary;
						background-color: transparent;
						transition: all .2s linear;
					}
				}

				.gfield-choice-input:checked~label {

					&::before {
						background-color: $color-primary !important;

					}
				}

				&:hover {

					label {
						&::before {
							background-color: $color-primary;
						}
					}
				}
			}

			.gfield_checkbox {

				.gchoice {

					input {
						margin-right: 10px;
					}

					label {
						display: inline;

						&::before {
							position: absolute;
							top: 13px;
							left: -26px;
							transform: translateY(-50%);
							content: "";
							width: 16px;
							height: 16px;
							border-radius: 3px;
							border: 2px solid $color-primary;
							background-color: transparent;
							transition: all .2s linear;
						}
						
						a {
							border-bottom: 1px solid;
						}
					}
				}
			}
		}
	}

	label,
	.gfield_label {
		font-size: $regular;
		line-height: $text-base-line-height;
		color: $color-primary;
		display: inline-block;
		display: none;
	}

	.input-group-addon {
		background-color: $color-gray-darker;
		color: $color-primary;
		border: 0;
	}

	fieldset {
		border: none;
	}

	select {
		padding: 8px 10px;
		background-color: white;
		height: 42px;
		color: $color-gray;
		width: 100%;
		border: 0;
		border: 1px solid $color-gray-lighter;
	}

	select[multiple] {
		height: auto;
	}

	input,
	textarea {
		width: 100%;
		border: 1px solid $color-primary;
		background-color: $color-gray;
		color: $color-text;
		padding: .6rem 1.1rem;
		appearance: none;
		resize: none;
		font-size: $regular;
		line-height: $text-base-line-height;
		font-family: $font-base;
		border-radius: 1rem;

		&::placeholder {
			font-size: inherit;
			color: $color-primary;
		}

		&:focus {
			outline: none !important;
			box-shadow: none !important;
			border: 1px solid $color-primary-dark !important;

			&::placeholder {
				color: $color-primary-dark;
			}
		}
	}

	input[type="checkbox"],
	input[type="radio"] {
		width: auto;
		appearance: inherit;
	}

	input[type="checkbox"] {
		appearance: checkbox;
	}

	input[type="radio"] {
		appearance: radio;
	}

	.gfield_description {
		margin: .5rem 0;
		font-size: $xsmall;
	}
}

.bg-gray {
	form {
		input,
		textarea {
			background-color: white;
		}
		.ts-control {
			border-radius: 1rem !important;
		}

	}
}

button {
	all: inherit;
}

.swiper {
	padding-bottom: 1.2rem;
}

.swiper-pagination {
	bottom: 0 !important;
	top: initial !important;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: .5rem;

	.swiper-pagination-bullet {
		margin: 0 !important;
		background-color: $color-primary;
		opacity: .3 !important;
		width: .6rem;
		height: .6rem;
		border-radius: 50%;

		&.swiper-pagination-bullet-active {
			opacity: 1 !important;
		}
	}

	&.swiper-pagination-lock {
		display: none !important;
	}
}

.container,
.container-fluid {
	position: relative;
	width: 100%;
	margin-inline: auto;
}

.container-fluid {
	max-width: 2560px;

	.inner-container {
		width: 100%;
		max-width: 91%;
		margin-inline: auto;
	}
}

.container {
	max-width: 85vw;

	@media (max-width:576px) {
		max-width: 89vw;
	}

	.inner-container {
		width: 100%;
		max-width: 83%;
		margin-inline: auto;
	}

	.smaller-container {
		width: 100%;
		max-width: 66%;
		margin-inline: auto;

		@media (max-width:1199px) {
			max-width: 83%;
		}

		@media (max-width:767px) {
			max-width: 100%;
		}
	}
}

.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;
}

.page-section {
	padding-top: $margin-base;
	padding-bottom: $margin-base;
}

.no-padding-top {
	padding-top: 0;
}

.no-padding-bottom {
	padding-bottom: 0;
}

.no-margin-top {
	margin-top: 0;
}

.no-margin-bottom {
	margin-bottom: 0;
}

#main-content {

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		&:first-child {
			margin-top: 0;
		}
	}
}

.acf-map {
	width: 100%;
	height: 700px;
	border: 0;
	border: 0;
}

.highlight {
	color: #ffffff;
	background-color: $color-primary;
}

#error-template {

	.container {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		padding-bottom: 4rem;
	}

	.error-details {
		margin-bottom: 30px;
		svg {
			max-height: 40svh;
		}
	}

	.error-actions {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: $gap;
		flex-wrap: wrap;
	}
}

#post-share {
	.social-share {
		padding: 0;
		margin: 0;
		list-style: none;

		li {
			display: inline-block;
			margin-right: 30px;

			&:last-child {
				margin-right: 0;
			}

			a {
				i {
					font-size: 25px;
				}
			}
		}
	}
}

/* Easter Egg */
@keyframes zoomIn {
    0% {
        transform: scale(0);
        opacity: 0;
		display: none;
    }
    100% {
        transform: scale(1);
        opacity: 1;
		display: block;
    }
}

@keyframes zoomOut {
    0% {
        transform: scale(1);
        opacity: 1;
		display: block;
    }
    100% {
        transform: scale(0);
        opacity: 0;
		display: none;
    }
}

#waiting.zoom-in {
    animation: zoomIn 0.5s forwards;
}

#waiting.zoom-out {
    animation: zoomOut 0.5s forwards;
}

#waiting-container {
	padding-left: 60px;
}

#header.zoom-in, #endgame.zoom-in, #no-page-header.zoom-in, #page-header.zoom-in, #main.zoom-in, #footer.zoom-in {
	animation: zoomIn 0.5s forwards;
}

#header.zoom-out, #endgame.zoom-out, #no-page-header.zoom-out, #page-header.zoom-out, #main.zoom-out, #footer.zoom-out {
	animation: zoomOut 0.5s forwards;
}


#waiting {
    transform-origin: center center;
	position: fixed;
    width: 190px;
    z-index: 1000;
    max-width: 190px;
    margin: 0 auto;
    bottom: 7svh;
    right: 27px;

	@media (max-width:991px) {
		max-width: 170px;
		width: 170px;
		right: 24px;
		bottom: 6svh;
	}

	@media (max-width:767px) {
        max-width: 110px;
        width: 100px;
		right: 20px;
		bottom: 5svh;
	}
}

#endgame {
	position: fixed;
	top: 0;
	z-index: 1100;
	height: 100svh;
	width: 100vw;
}

.loading-spinner {
	width: 50px;
	height: 50px;
	margin: 0 auto;
	float: none;
	background-image: url('../images/ajax-loader.svg');
	background-size: 100%;
	background-position: center;
	background-repeat: no-repeat;
}

#pagination {
	text-align: center;

	.navigation {
		ul {
			padding: 0;
			margin: 0;

			li {
				display: inline-block;

				a {
					color: #ffffff;
					text-decoration: none;
					background-color: $color-gray-dark;
					cursor: pointer;
					padding: 5px 13px;
					border-radius: 5px;
					display: block;

					&:hover {
						background-color: $color-primary;
					}
				}

				&.active {
					a {
						background-color: $color-primary;
					}
				}
			}
		}
	}
}

//selects tom select package
.ts-wrapper {

	.ts-control {
		font-size: $regular;
		line-height: $text-base-line-height;
		font-weight: 300;
		color: $color-primary;
		display: flex;
		align-items: center;
		justify-content: center;
		width: fit-content;
		gap: 1rem;
		background-color: $color-gray;
		transition: all .2s linear;
		border-radius: 1rem;
		padding: .6rem 1.1rem !important;
		padding-right: 3.2rem !important;
		position: relative;
		text-transform: uppercase;
		min-width: 3.5rem;
		width: 100%;

		&:focus-visible {
			outline: none;
			box-shadow: none;
		}

		&::after {
			width: 1rem;
			height: 1rem;
			min-width: 1rem;
			min-height: 1rem;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			background-color: transparent;
			content: "\f107";
			font-family: $fontawesome;
			font-weight: 400;
			color: $color-primary;
			font-size: .5rem;
			position: absolute;
			top: 50%;
			right: $medium;
			transform: translateY(-50%);
			transition: all .2s linear;
		}
	}

	.ts-control,
	.ts-dropdown {
		color: $color-primary;
		font-size: 1rem;
		line-height: normal;
		border: 1px solid $color-primary;
		box-shadow: none;
		background-color: $color-gray;

		input {
			font-size: inherit;
			line-height: inherit;
			border-radius: 0;

			&:focus {
				border: 0 !important;
			}
		}
	}

	.ts-dropdown {
		margin-top: 0;

		.option {
			padding: .6rem 1.1rem;
			font-weight: 300;
		}

		.selected {
			color: white;
			font-weight: 600;
			background-color: $color-primary;
		}

		//hover
		.active {
			color: white;
			background-color: $color-primary-dark;
		}
	}

	&.dropdown-active {

		.ts-control,
		.ts-dropdown {
			border-color: $color-primary;
		}

		.ts-control {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;

			&::after {
				transform: translateY(-50%) rotate(180deg);
			}
		}

		.ts-dropdown {
			border-top: none;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
	}
}

.filters-select {
	width: 100%;
	display: flex;
	gap: 1rem;
}

#cookie-notice {
	.button {
		font-size: 14px;
		padding: 5px 10px;
	}
}

/* WordPress */
.wp-caption,
.gallery-caption {
	color: $color-gray-light;
	font-size: $xsmall;
	font-size: 0.8125rem;
	font-style: italic;
	margin-bottom: 1.5em;
	max-width: 100%;
}

.wp-caption img[class*="wp-image-"] {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.wp-caption .wp-caption-text {
	margin: 0.8075em 0;
	font-size: 14px;
}

.bypostauthor>.comment-body>.comment-meta>.comment-author .avatar {
	border: 1px solid #333;
	padding: 2px;
}

.alignleft {
	display: inline;
	float: left;
	margin-right: 1.5em;
}

.alignright {
	display: inline;
	float: right;
	margin-left: 1.5em;
}

.aligncenter {
	clear: both;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

img.alignleft {
	float: left;
	margin-right: 1.5em;
}

img.alignright {
	float: right;
	margin-left: 1.5em;
}

.fitlers-container {
	padding: 0 0 0.5rem;

	.filters {
		margin-bottom: 2rem;
		display: flex;
		gap: 1rem;

		.filters-button {
			.button-select {
				display: flex;
				flex-wrap: wrap;

				.filter {
					min-width: fit-content;
				}
			}
		}
	}

	@media (max-width: 1199px) {

		.filters {
			flex-direction: column;
		}

	}

	@media (max-width:991px) {
		padding: 0 0 0.5rem;
	}

	@media (max-width: 767px) {
		.filters {
			.filters-select {
				flex-direction: column;
			}
		}
	}
}

.tingle-modal.gpnf-modal {

    .tingle-modal-box {
        border-radius: 1rem 1rem 0 0;

        .tingle-modal-box__footer {

            .tingle-btn--primary {
                background-color: #1fb43f;
                border-radius: 1rem;
                min-width: 205px;
            }

            .tingle-btn--default {
                background-color: $color-primary;
                border-radius: 1rem;
            }

			.tingle-btn--danger {
				border-radius: 1rem;
			}
        }
    }

	@media (max-width:767px) {
		.tingle-btn {
			position: relative;
			border-radius: 1rem !important;
			text-align: center;
		}
	}
} 

#ui-datepicker-div {
    background-color: #fff;
    border-radius: 1rem;
    border: 1px solid $color-primary;
    top: 422.5px !important;

    .ui-datepicker-header {
        padding-top: 12px;

        a {
            padding-left: 12px;
            color: $color-primary;
        }
    }

    .ui-datepicker-calendar {
        border: none;
        margin-top: 15px;
        margin-bottom: 0;
        
        tbody {

            tr {

                td {
                    
                    a {
                        color: $color-primary;
                    }
                }
            }
        }
    }

    .ui-datepicker-title {
        
        .ui-datepicker-month {
            padding: 3px;
            margin-left: 12px;
            border-radius: 1rem;
            border: 1px solid $color-primary;
        }

        .ui-datepicker-year {
            padding: 3px;
            margin-left: 12px;
            border-radius: 1rem;
            border: 1px solid $color-primary;
        }
    }
}