@import "../../assets/styles/scss/variables";


#cta-footer {
  margin-top: 7rem;
  margin-bottom: 5rem;

  @media (max-width:767px) {
    margin-top: 5rem;
    margin-bottom: 3rem;
  }
 
  h2 {
    margin-bottom: 3.2rem;

    @media (max-width:767px) {
      margin-bottom: 1.5rem;
    }
  }

  .blocs {
    grid-template-columns: repeat(2, 1fr);

    @media (max-width:767px) {
      display: flex;
      flex-direction: column;
    }
  }

  .bloc {
    padding: 3rem;
    padding-right: 1rem;
    padding-bottom: 4.5rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: $gap;

    .btn-primary {
      margin-top: 1rem;
    }

    @media (max-width:1260px) {
      display: flex;
      flex-direction: column;
      padding: 3rem;
    }

    @media (max-width:767px) {
      padding: 1.5rem;
    }
  }
}