@import "../../assets/styles/scss/variables";

.advantages-section {
    display: flex;
    flex-direction: column;
    margin-top: 7.78rem;

    .section-title {
        margin-left: 0;
    }

    .section-content {
        display: flex;
        flex-direction: column;
        gap: 2.22rem;

        .top {
            width: 80%;
            display: grid;
            gap: 10rem;
            grid-template-columns: calc(40% - 5rem) calc(60% - 5rem);
        }

        .bottom {
            display: grid;
            gap: 1.72rem;
            grid-template-columns: repeat(3, 1fr);
        }
    }

    @media (max-width: 1399px) {

        .section-content {
            display: flex;
            flex-direction: column;
            gap: 2.22rem;
    
            .top {
                width: 100%;
                display: grid;
                gap: 5rem;
                grid-template-columns: calc(50% - 2.5rem) calc(50% - 2.5rem);
            }
        }
    }

    @media (max-width: 991px) {

        .section-content {
            .bottom {
                display: flex;
                flex-direction: column;
            }
        }
    }

    @media (max-width: 767px) {

        .section-content {
            .top {
                display: flex;
                gap: 2.22rem;
                flex-direction: column;
            }
        }
    }
}