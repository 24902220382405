//
// Header Styles
// --------------------------------------------------

#header {
	width: 100%;
	background-color: white;
	display: flex;
	align-items: center;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 200;
	transition: all 0.4s linear;
	padding-top: 3rem;
	border-bottom: 1px solid $color-gray;

	.inner-container {
		position: relative;
	}

	&.sticky {
		padding-top: 1.5rem;

		#logo {
			max-width: 6rem;
			transition: all 0.4s linear;
			img {
				width: 100%;
			}
		}

		#header-extras {
			bottom: calc(100% + 1.5rem + 5rem);
			opacity: 0;
		}
	}

	@media (max-width:1199px) {
		padding: 1.2rem 0;

		&.sticky {
			padding: 1.2rem 0;

			#logo {
				max-width: 7.8rem;
			}	
		}
	}

	#header-extras {
		position: absolute;
		right: 0;
		bottom: calc(100% + .5rem);
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 1.5rem;
		font-size: $xsmall;
		line-height: normal;
		color: $color-gray-dark;
		opacity: 1;
		transition: all .3s ease-in-out;

		@media (max-width:1199px) {
			display: none;

			&.mobile {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				position: static;
				margin-top: 3rem;
				opacity: 1 !important;

				#global-search-container {
					width: 100%;
				}

				ul {
					gap: 2rem;
					flex-wrap: wrap;

					li {
						border: none !important;
						width: fit-content !important;

						a {
							padding: 0;
						}
					}
				}
			}
		}

		a {
			color: inherit;

			&:hover {
				color: initial;
			}
		}

		#global-search-container {
			border-bottom: 1px solid $color-gray-dark;

			form {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				gap: .2rem;
			}

			input {
				border-radius: 0;
				border: none;
				padding: .2rem;
				font-weight: 300;
				color: $color-gray-dark;
				background-color: white;
				min-width: 12rem;

				&::placeholder {
					color: $color-gray-dark;
				}

				&:focus {
					border: none !important;

					&::placeholder {
						color: $color-primary;
					}
				}
			}

			button {
				padding: 0;
				background-color: transparent;
				color: $color-gray-dark;
			}
		}

		#secondary-menu {

			ul {
				padding: 0;
				margin: 0;
				list-style: none;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				gap: 1.5rem;
			}
		}
	}

	#header-content {
		display: flex;
		align-items: flex-end;
		height: 100%;
		width: 100%;
		justify-content: space-between;

		@media (max-width:1199px) {
			align-items: center;
		}
	}

	#logo {
		max-width: 7.8rem;
		width: 100%;
		transition: all 0.4s linear;
		margin-bottom: 1.1rem;

		@media (max-width:1199px) {
			margin-bottom: 0;
		}
	}

	#menu {
		transition: all 0.4s linear;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 4.5rem;

		#menu-main-container {
			display: flex;
			align-items: flex-start;
			justify-content: flex-end;
			gap: 8rem;
			width: 100%;

			.btn-primary {
				margin-top: -.5rem;
			}


			ul {
				margin: 0;
				padding: 0;
				list-style: none;
				text-align: right;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				gap: 3.5rem;

				@media (max-width:1600px) {
					gap: 2.5rem;
				}

				@media (max-width:1441px) {
					gap: 1.5rem;
				}
				li.first-level {
					position: relative;
					a {
						font-size:1rem;
						line-height: normal;
						display: block;
						color: black;
						font-weight: 500;
						position: relative;
						padding-bottom: 1.7rem;

						&:hover {
							color: $color-primary;
						}
					}

					&.is-active {

						&::after {
							content: "";
							width: 100%;
							height: .5rem;
							border-radius: .4rem;
							background-color: $color-primary;
							left: 0;
							bottom: -0.25rem;
							position: absolute;
						}

						>a {

							svg {
								transform: rotate(180deg);

								path {
									fill: $color-primary;
								}	
							}	
						}
					}
					
					&.current-item {
						a {

							&::after {
								content: "";
								width: 100%;
								height: .5rem;
								border-radius: .4rem;
								background-color: $color-primary;
								left: 0;
								bottom: -.25rem;
								position: absolute;
							}
						}
					}

					&.has-children {

						a {
							display: flex;
							align-items: center;
							justify-content: space-between;
							gap: .5rem;
						}
					}
				}
			}
		}

		#menu-main-container {
			@media (max-width:1199px) {
				display: none !important;
				visibility: hidden;
			}
		}

		#burgermenu-trigger {
			display: none;
			font-size: 1.1rem;
			padding: .7rem .8rem;

			a {
				color: inherit;
				display: flex;
				&:hover {
					color: white;
				}
			}

			@media (max-width:1199px) {
				display: flex;
			}

			i {
				&.fa-xmark {
					display: none;
				}
			}

			&.is-active {

				i {
					&.fa-xmark {
						display: initial;
					}
					&.fa-bars {
						display: none;
					}
				}
			}
		}

		.mega-menu-container {
			position: absolute;
			left: 0;
			top: calc(100% + .5rem);
			width: 100%;
			overflow: hidden;
			display: none;

			&.is-open {
				display: block;
			}

			.mega-menu {
				background-color: white;
				border-radius: $rounded;
				overflow: hidden;
				overflow-y: auto;
				max-height: 30rem;
				padding: 1.5rem 9rem;
				padding-bottom: 3rem;
			}

			.second-level-container {
				justify-content: flex-start !important;
				text-align: left;
				padding: 0;
				margin: 0;
				list-style: none;
				width: 100%;
				display: grid !important;
				gap: $gap;
				grid-template-columns: repeat(2, 1fr);
				column-gap: $gap !important;
				row-gap: 1rem !important;

				li {
					width: 100%;
					
					&:last-child {
						a {
							border-bottom: none;
						}
					}
					a {
						border-bottom: 1px solid #d60b521a;
						padding: 1.5rem .3rem;
						padding-right: 8rem;
						display: flex !important;
						text-align: left;
						align-items: flex-start;
						gap: 1rem;
						white-space: nowrap;
						color: black;

						img {
							width: 2.1rem;
							height: auto;
						}

						&:hover {
							color: $color-primary;
						}
					}
				}
			}
		}

		#mobile-menu {
			display: none !important;
			visibility: hidden;
			position: fixed;
			width: 100%;
			height: calc(100vh - 106px);
			max-height: calc(100dvh - 106px);
			left: 0;
			top: 106px;
			z-index: -1;

			.container-fluid,
			#mobile-menu-container,
			.inner-container {
				height: 100%;
			}
			
			.mobile-menu-container {
				background-color: white;
				height: 100%;
				margin-inline: auto;
				

				#mobile-menu-container {
					position: relative;
					padding-top: .3rem;
					padding-bottom: 1rem;
				}
			}

			.top-part {
				height: calc(100% - 238px - 1.2rem);
				max-height: calc(100dvh - 238px - 1.2rem);
				overflow-y: auto;
				
				@media (max-width:767px) {
					height: calc(100% - 221px - 1.2rem);
					max-height: calc(100dvh - 221px - 1.2rem);
				}
			}

			ul {
				list-style: none;
				padding: 0;
				margin: 0;

				li {
					width: 100%;
					border-bottom: 1px solid #d60b521a;

					&.current-item {
						>a {
							color: $color-primary;
						}
					}

					&.mobile-first-level {

						&.has-children {
							position: relative;

							&.is-active {

								>a {

									svg {
										transform: rotate(180deg);
									}
								}
							}

							>a {
								align-items: center;
								justify-content: space-between;
								position: relative;
								gap: .5rem;
							}
						}
					}

					&.mobile-second-level {
						position: relative;
						border: none;
						a {
							padding: .5rem .8rem;
						}
					}

					a {
						font-weight: 500;
						display: flex;
						color: black;
						line-height: normal;
						padding: 1.2rem .3rem;

						&:hover {
							color: $color-primary-dark;
						}
					}

					&:last-child {
						border-bottom: none;
					}	

					ul.mobile-second-level-container {
						display: none;

						&.is-open {
							display: block;
							padding: .7rem 0;
						}
					}
				}
			}

			.bottom-part {
				margin-top: 1.2rem;
			}

			@media (max-width:1199px) {
				visibility: visible;

				&.is-open {
					display: block !important;
				}
			}

			@media (max-width:767px) {
				top: 94px;
				height: calc(100vh - 94px);
			}
		}

		@media (max-width:1199px) {
			position: relative;
		}
	}

}

body.mega-menu-open {
	position: relative;

	.mega-menu-overlay {
		position: fixed;
		left: 0;
		top: 0;
		width: 100vw;
		height: 100vw;
		background-color: #00000045;
		z-index: 20;
	}
}
