@import "../../assets/styles/scss/variables";

.button-select {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  list-style-type: none;
  margin: 0;
  padding: 0;

  .filter {
    background-color: $color-gray;
    color: $color-primary;
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.6rem;
    cursor: pointer;

    button {
      padding: 0.8rem 1.1rem;
    }

    &:hover {
      background-color: $color-primary;
      color: white;
    }

    &.is-selected {
      background-color: $color-primary;
      color: white;
    }
  }
}