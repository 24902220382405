//
// Footer Styles
// --------------------------------------------------

#footer {

	#footer-top {
		color: white;
		padding: 3.5rem 0;
		font-size: 1rem;
		line-height: 145%;

		@media (max-width:576px) {
			padding: 3rem 1.5rem;
		}

		a {
			color: inherit;

			&:hover {
				color: inherit;
			}
		}

		.top {
			margin-bottom: 1rem;
		}

		.logo {
			width: 7.6rem;
			height: auto;

			img,
			svg {
				width: 100%;
				height: auto;
			}
		}

		.bottom {
			display: grid;
			grid-template-columns: 5fr 7fr;
			gap: $gap;

			.left {
				display: flex;
				align-items: flex-start;
				gap: 3.7rem;

				@media (max-width:767px) {
					flex-direction: column;
					gap: 1.9rem;
				}
			}

			.contact {

				a {
					display: block;
				}
			}

			#footer-menu {
				ul {
					padding: 0;
					margin: 0;
					list-style: none;
					display: grid;
					grid-template-columns: max-content max-content;
					gap: 6rem;
					row-gap: 1rem;

					li {
						transition: all .2s linear;
						width: fit-content;

						a {
							display: flex;
							align-items: center;
							justify-content: flex-start;
							gap: $xxsmall;
							width: fit-content;
							transition: all .2s linear;
						
							&::before {
								content: url('../images/arrow-white.svg');
								width: $xsmall;
								height: auto;
							}
						}

						&:hover {
							padding-left: $xsmall;
						}
					}

					@media (max-width:576px) {
						display: flex;
						flex-direction: column;
						gap: .7rem;
					}
				}
			}

			@media (max-width:1199px) {
				display: flex;
				flex-direction: column;
			}

			@media (max-width:767px) {
				gap: 2.2rem;
			}
		}
	}

	#footer-bottom {
		font-size: .8rem;
		line-height: 181%;
		font-weight: 400;
		padding: .8rem 0;
		padding-top: 1rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: .5rem;

		.left {
			display: flex;
			flex-wrap: wrap;

			span {
				display: inline-block;
				margin-right: 1rem;
			}
		}

		@media (max-width:991px) {
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
		}
	}
}
.powered {
	position: relative;
	color: inherit;
	&:hover {
		color: inherit;
		text-decoration: none;
		#logo-alt-footer-trois {
			fill: #FFD94A;
			transform: scale(1.2);
		}
		#logo-alt-footer-deux {
			fill: #FFD94A;
			transform: scale(1.2);
		}
		#logo-alt-footer-un {
			fill: #FFD94A;
			transform: scale(1.2);
		}
		&:after {
			width: 100px;
		}
	}
	svg {
		overflow: visible;
		width: 50px;
		height: 24px;
		margin: 0 0 0 5px;
		position: relative;
		top: -2px;
		vertical-align: middle;
		width: 34px;
		#logo-alt-footer-trois {
			fill: $color-text;
			transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
			transform: scale(1);
			transform-origin: center;
		}
		#logo-alt-footer-deux {
			fill: $color-text;
			transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s;
			transform: scale(1);
			transform-origin: center;
		}
		#logo-alt-footer-un {
			fill: $color-text;
			transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.4s;
			transform: scale(1);
			transform-origin: center;
		}
	}
	&:after {
		display: block;
		content: '';
		position: absolute;
		bottom: -2px;
		left: 83px;
		width: 0px;
		height: 2px;
		background-color: #FFD94A;
		transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
	}
}
