@import "../../assets/styles/scss/variables";

.accordion {
  position: relative;

  &::after {
    content:"";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: $color-primary;
    opacity: .3;
  }

  &:last-child {
    &::after {
      display: none;
    }
  }

  .accordion-title {
    padding: $large 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $small;
    cursor: pointer;

    .icon {
      background: $color-primary;
      border-radius: 100%;
      width: 1.4rem;
      height: 1.4rem;
      aspect-ratio: 1;
      min-width: 1.4rem;
      min-height: 1.4rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        transform: rotate(90deg);
        transform-origin: center;
        transition: all 0.3s ease;
      }
    }

    &.is-open {
      img {
        margin-top: -2px;
        transform: rotate(270deg);
        transition: all 0.3s ease;
      }
    }
  }

  .accordion-content {
    display: none;
    padding: 0;
    padding-bottom: $large;

    ul {
      padding-left: 20px;
    }
  }
}