@import "../../assets/styles/scss/variables";

.bloc-highlight-link {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: .7rem;

  @media (max-width:767px) {
    padding: 1.5rem;
  }

  .icon {
    width: 2.3rem;
    min-width: 2.3rem;
    height: 2.3rem;
    min-height: 2.3rem;
    border: 1px solid $color-primary;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 1.7rem;
      height: auto;
      transition: all .2s linear;
    }
  }

  &:hover {
    color: white;
    background-color: $color-primary;

    .icon {
      border-color: white;

      svg {
        path {
          stroke: white;
        }
      }
    }
  }
}