@import "../../assets/styles/scss/variables";

#quick-access {
  color: white;
  padding: 1.5rem 1.8rem;
  padding-bottom: 3rem;
  width: fit-content;
  min-width: 16rem;

  @media (max-width:767px) {
    min-width: auto;
    width: 100%;
  }

  p.bold {
    padding-bottom: 1.1rem;
    border-bottom: 1px solid $color-gray;
    margin-bottom: 1.1rem;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      margin-bottom: 1.1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: .5rem;
      width: 100%;

      &::after {
        content: url('../../assets/images/arrow-white.svg');
        width: .8rem;
        height: auto;
      }
    }
  }

  a {
    color: inherit;
    display: block;

    &:hover {
      color: inherit;
    }
  }
}