@import "../../assets/styles/scss/variables";

section#helpful-docs {
  max-width: 26rem;
  width: 100%;

  h2.large {
    color: $color-primary;
    margin-bottom: 2rem;
  }

  .docs {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}