@import "../../assets/styles/scss/variables";

.homepage-header {
  height: 100svh;
  min-height: 100svh;
  padding-top: $headerHeight;

  .inner-container {
    height: 100%;
    position: relative;

    .content {
      height: 100%;
      width: 55%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      padding: 0 0 3.35rem;

      p,
      h1,
      h2 {
        color: white;
        z-index: 1;
        font-weight: 500;
      }

      p {
        font-size: clamp(1rem, 4vw, 1.6rem);
        padding: 0 0 1.1rem;

        strong {
          font-weight: 500;
          color: $color-primary;
        }
      }

      h1 {
        padding: 0 0 3.6rem;
      }

      h2 {
        font-size: clamp(1rem, 4vw, 1.6rem);
      }
    }

    #quick-access {
      position: absolute;
      right: 0;
      bottom: -3rem;
      z-index: 1;
    }
  }

  .homepage-slider {
    position: absolute;
    width: 100vw;
    overflow: visible;
    height: 100svh;
    min-height: 100svh;
    margin-top: -$headerHeight;
    padding-bottom: 0;

    .swiper-wrapper {
      height: 100%;
      will-change: transform;
      position: absolute;
      overflow: hidden;

      &:before {
        opacity: 1;
        content: '';
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 100;
        background-color: rgba(0, 0, 0, 0.3);
      }
    }

    .swiper-slide {
      height: 100%;

      .slide-image {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        transform: scale3d(1.1, 1.1, 1);
        will-change: transform;
        transition: transform 1400ms ease;
      }

      &.swiper-slide-active,
      &.swiper-slide-duplicate-active {
        .slide-image {
          transform: scale3d(1, 1, 1);

          &:before {
            opacity: 0;
          }

        }
      }
    }

    .swiper-pagination.homepage-bullet {
      position: initial !important;
      flex-wrap: wrap;

      .swiper-pagination-bullet {
        background: none;
        color: white;
        opacity: 1 !important;
        display: flex;
        width: fit-content;
        position: relative;
        height: fit-content;
        margin: 3px 0!important;
        border-radius: 0 !important;

        .backdrop {
          height: 100%;
          font-size: clamp(1rem, 4vw, 1.6rem);
          font-weight: 500;
          line-height: normal;
          position: absolute;
          display: flex;
          width: 0;
          transition: width 0s ease;
          overflow: hidden;
          word-break: keep-all;
          flex-wrap: nowrap;
          left: 0;
          line-break: anywhere;
          white-space: pre;
          -webkit-text-stroke: 0.1px $color-primary;
        }

        &.swiper-pagination-bullet-active {

          .backdrop {
            width: 100%;
            transition: width 5s ease;
            color: $color-primary;
          }
        }
      }
    }
  }

  @media(max-width: 991px) {
    height: calc(27rem + $headerTab);
    margin-top: $headerTab;

    .homepage-slider {
      height: calc(27rem + $headerTab);

      .swiper-pagination.homepage-bullet {
        gap: 0;
        .swiper-pagination-bullet {
          height: auto;
        }
      }
    }

    .inner-container {
      position: relative;

      .content {
        width: 100%;
        padding: 0;
        justify-content: center;

        p {
          padding: 0;
        }

        h1 {
          padding: 0 0 5.5rem;
        }
      }

      #quick-access {
        position: absolute;
        right: 0;
        bottom: auto;
        top: calc(100% - 3.28rem);
        z-index: 1;
      }
    }
  }

  @media(max-width: 767px) {
    min-height: 80vh;
    height: calc(85svh + $headerPhone);
    margin-top: $headerPhone;
    padding-top: $headerPhone;

    .homepage-slider {
      min-height: 80vh;
      height: calc(85svh + $headerPhone);
    }
  }
}