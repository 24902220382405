@import "../../assets/styles/scss/variables";

.services-section {
    display: flex;
    flex-direction: column;
    margin-top: 7.78rem;

    .section-title {
        margin-left: 0;
    }

    .section-content {
        display: grid;
        grid-template-columns: 40% 60%;

        .left {
            display: flex;
            flex-direction: column;
            padding-right: 20%;
        }

        .right {
            display: grid;
            row-gap: 1.67rem;
            column-gap: 1.44rem;
            grid-template-columns: repeat(3, 1fr);

            .icon-card {
                h4 {
                    color: $color-primary;
                }
            }
        }
    }

    @media (max-width: 1599px) {

        .section-content {

            .right {
                display: grid;
                row-gap: 1.67rem;
                column-gap: 1.44rem;
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }

    @media (max-width: 1199px) {
        .section-content {
            display: flex;
            flex-direction: column;

            .left {
                padding-right: 0;
                padding-bottom: 2.78rem;
            }

            .right {
                display: grid;
                row-gap: 1.67rem;
                column-gap: 1.44rem;
                grid-template-columns: repeat(3, 1fr);

            }
        }

        .section-content {
            .right {
                display: grid;
                row-gap: 1.67rem;
                column-gap: 1.44rem;
                grid-template-columns: repeat(3, 1fr);

            }
        }
    }

    @media (max-width: 991px) {
        .section-content {
            .right {
                grid-template-columns: repeat(2, 1fr);

            }
        }
    }

    @media (max-width: 767px) {
        .section-content {
            .right {
                grid-template-columns: repeat(1, 1fr);

            }
        }
    }
}