@import "../../assets/styles/scss/variables";

.manual-section {
    margin: 3.7rem 0;
    .section-image {
        display: flex;
        height: 52rem;
        width: 100%;

        img {
            width: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    &.no-img {
        padding: 0 3.45em;

        .section-title {
            margin-left: 0;
            margin-bottom: 2rem;
        }

        .section-content {
            margin-top: 0;

            .left {
                background-color: white;
                border-radius: 0;
                padding: 0 7.2rem 0 0; 
            }

            .right {
                padding: 2rem 0 0;
    
                h4 {
                    color: $color-primary;
                }
            }
        }
    }

    .section-title {
        margin-left: 3.45rem;
    }

    .section-content {
        margin-top: -11.35rem;
        display: grid;
        gap: 1.2rem;
        grid-template-columns: calc(55% - 0.6rem) calc(45% - 0.6rem);

        .left {
            background-color: white;
            border-radius: 0 $rounded $rounded $rounded;
            padding: 2.5rem 7.2rem 1.9rem 3.45em;
            
            h2 {
                padding: 0 0 2rem;
            }
        }

        .right {
            padding: 15.2rem 0 0;

            h4 {
                color: $color-primary;
            }
        }
    }

    @media (max-width: 991px) {
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 2.5rem 0;

        &.no-img {
            padding: 0;
            align-items: flex-start;

            .section-content {
                .left {
                    padding: 0;
                }
            }
        }
        
        .section-title {
            margin-left: 0;
            width: 100%;
        }

        .section-content {
            display: flex;
            flex-direction: column;
            margin-top: -5.15rem;
            max-width: 90%;

            .left {
                border-radius: $rounded;
                padding: 1.5rem;
            }

            .right {
                padding: 0;
            }
        }

        .section-image {
            height: 27rem;
        }
    }
}