@import "../../assets/styles/scss/variables";

.blog-bloc {
    height: 100%;
    position: relative;
    background-color: $color-gray;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .blog-image {
        border-radius: $rounded $rounded 0 0;

        img {
            width: 100%;
            height: 12.2rem;
            object-fit: cover;
        }
    }

    &.no-img {
        .blog-content {
            justify-content: space-between;
            padding: 1rem 2rem 1.7rem;
        }
    }

    .blog-content {
        height: 100%;
        position: relative;
        min-height: 10rem;
        padding: 1rem 2rem 4rem;
        display: flex;
        flex-direction: column;

        .top {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            padding: 0 0 2.5rem;

            .post-title {
                color: black;
            }

            .blog-categories {
                font-size: 0.9rem;
                font-weight: 500;
                min-height: 2rem;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                text-transform: uppercase;
                color: $color-primary;
                flex-wrap: wrap;

                .blog-category {
                    padding: 1rem 0 0;
                }

                .icons {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: flex-end;
                    gap: 0.5rem;
                    padding-top: 1rem;
                }

                .profil-icon {
                    width: 1.8rem;
                    height: 1.8rem;
                }
            }
        }
    
        .bottom {
            color: $color-gray-dark;
            font-size: 0.9rem;
        }

    }

    &::after {
        content: url('../../assets/images/arrow.svg');
        position: absolute;
        right: 1.5rem;
        bottom: 1.5rem;
    }

    &:hover {
        background-color: $color-primary;
        transition: all .3s ease;

        .blog-content {
            .top {
                color: white;

                .post-title {
                    color: white;
                }

                .blog-categories {
                    color: white;
                }
                .profil-icon {
                    mix-blend-mode: color-dodge;
                    filter: invert(1);
                }
            }

            .bottom {
                color: white;
            }
        }

        &::after {
            mix-blend-mode: color-dodge;
            filter: invert(1);
        }
    }
}