@import "../../assets/styles/scss/variables";

#page-header.header-news {
  margin-bottom: 3rem;
  margin-top: $headerHeight;
  padding-top: 7rem;

  @media (max-width:991px) {
    padding-top: 0rem;
    margin-bottom: 2rem;
  }

  .inner-container {
    @media (max-width:767px) {
      max-width: 100%;
    }
  }

  .img-container {
    margin-bottom: 3rem;

    img {
      width: 100%;
    }
  }

  .top {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: .5rem;
    color: $color-primary;
    text-transform: uppercase;

    >div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: .5rem;

      p {
        margin: 0;
      }

      .date {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: .5rem;
        &::before {
          content: "";
          width: .25rem;
          height: .25rem;
          min-width: .25rem;
          min-height: .25rem;
          background-color: $color-primary;
          border-radius: 50%;
        }
      }
    }

    .profiles {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: .5rem;

      .profil {

        img {
          width: 2.2rem;
          height: auto;
        }
      }
    }

    @media (max-width:767px) {
      flex-direction: column-reverse;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 1rem;
    }
  }

  .title {
    display: grid;
    grid-template-columns: max-content auto;
    align-items: flex-end;
    gap: $gap;
    width: 108.5%;
    transform: translateX(-8.5%);
    margin-bottom: 2rem;

    @media (max-width:1199px) {
      width: 100%;
      transform: none;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}