@import "../../assets/styles/scss/variables";

#page-header.header-journal {
  margin-top: $headerHeight;
  padding-top: $massive;
  margin-bottom: 7rem;

  @media (max-width:767px) {
    margin-top: $headerPhone;
  }

  .grid {
    grid-template-columns: 41% 25%;
    gap: 13rem;

    @media (max-width:1260px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 4rem;
    }

    @media (max-width:767px) {
      display: flex;
      flex-direction: column;

      .left {
        margin-top: 0;
      }
    }
  }

  .left {
    display: flex;
    flex-direction: column;
    gap: $gap;
    margin-top: 3.7rem;
  }

  .themes-section {
    padding: 1.5rem;

    h3.regular {
      color: $color-primary;
    }

    ul {
      display: flex;
      align-items: baseline;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding: 0;
      margin: 0;
      list-style: none;
      margin-top: 0.1rem;
    }
  }
}