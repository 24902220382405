@import "../../assets/styles/scss/variables";

#page-header.header-event {
  margin-bottom: 3rem;
  margin-top: $headerHeight;
  padding-top: 7rem;


  @media (max-width:1199px) {
    margin-top: $headerTab;
  }

  @media (max-width:991px) {
    padding-top: 4rem;
    margin-bottom: 2rem;
  }

  @media(max-width: 767px) {
    margin-top: $headerPhone;
  }

  p.date {
    font-size: 2.2rem;
    line-height: normal;
    font-weight: 400;
    color: $color-primary;
    margin-bottom: 1rem;
  }

  h2.large {
    margin-top: 2rem;
  }

  .title {
    display: grid;
    grid-template-columns: max-content auto;
    align-items: flex-end;
    gap: $gap;
    width: 108.5%;
    transform: translateX(-8.5%);

    @media (max-width:1199px) {
      width: 100%;
      transform: none;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}