@import "../../assets/styles/scss/variables";

.member {
  border-radius: $rounded;
  display: flex;
  flex-direction: column;

  .member-img {
    height: 24.25vw;

    img {
      max-height: inherit;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: top;
    }
  }

  .member-infos {
    padding: 1.5rem 0 1.7rem 1.5rem;
    gap: 0.25rem;
    background-color: $color-gray;

    p, h4, a {
      margin-bottom: 0.25rem;
    }
  }
        
  @media (max-width: 1399px) {
    .member-img {
      height: 30vw;
    }
  }
        
  @media (max-width: 991px) {
    .member-img {
      height: 35vw;
    }
  }
        
  @media (max-width: 767px) {
    .member-img {
      height: 40vw;
    }
  }
        
  @media (max-width: 576px) {
    .member-img {
      height: 50vh;
    }
  }
}