//
// Base Styles
// --------------------------------------------------

html,
body {
  font-family: $font-base;
  font-size: $regular; //20px
  line-height: $text-base-line-height;
  font-weight: 300;
  color: $color-text;
  scroll-behavior: smooth;

  @media (max-width:767px) {
    font-size: $regular-mobile;
  }
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  margin: 0;
}

.unscroll {
  overflow-y: hidden !important;
}

.is-hidden,
.hidden {
  display: none !important;
}

.invisible,
.not-visible {
  visibility: hidden;
}

.xxsmall {
  font-size: $xxsmall;
  line-height: normal;
  font-weight: 300;
}

.xsmall {
  font-size: $xsmall;
  line-height: normal;
  font-weight: 300;
}

.small {
  font-size: $small;
  line-height: normal;
  font-weight: 300;
}

p,
.regular {
  font-size: clamp(0.9rem, 2vw, $regular);
  line-height: 160%;
  font-weight: 300;
}

p {
  margin-bottom: .8rem;
  font-weight: 300;

  &:last-child {
    margin-bottom: 0;
  }

  strong {
    font-weight: 600;
  }
}

.bold {
  font-weight: 600;
}

h6,
.medium {
  font-size: clamp(1.1rem, 4vw, $medium);
  line-height: normal;
  font-weight: 500;
}

h5,
.big {
  font-size: clamp(1.2rem, 4vw, $big);
  line-height: normal;
  font-weight: 500;
}

h4,
.large:not(input):not(textarea):not(.ts-wrapper) {
  font-size: clamp(1.5rem, 4vw, $large);
  line-height: normal;
  font-weight: 500;
}

h3,
.massive {
  font-size: clamp(2rem, 4vw, $massive);
  line-height: normal;
  font-weight: 300;
}

h2,
.huge {
  font-size: clamp(2.4rem, 4vw, $huge);
  line-height: normal;
  font-weight: 300;
}

h1,
.gigantic {
  font-size: clamp(3rem, 4vw, $gigantic);
  line-height: 104%;
  font-weight: 400;
}

.rounded {
  border-radius: $rounded;
  overflow: hidden;
}

.bg-red,
.bg-primary {
  background-color: $color-primary;
}

.bg-red-dark,
.bg-secondary,
.bg-primary-dark {
  background-color: $color-secondary;
}

.bg-gray {
  background-color: $color-gray;
}

.color-red,
.color-primary {
  color: $color-primary;
}

.color-red-dark,
.color-secondary,
.color-primary-dark {
  color: $color-secondary;
}

.subtitle,
.highlight-title {
  color: $color-primary;
  text-transform: uppercase;
  font-size: 1.6rem;
  line-height: normal;
  font-weight: bold;
  padding-top: $xsmall;
  position: relative;
  width: fit-content;
  margin-bottom: 2.5rem;

  &::before {
    content: "";
    width: 5.45rem;
    height: 2px;
    background-color: $color-primary;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.grid {
  display: grid;
  gap: $gap;

  @media (max-width:767px) {
    display: flex;
    flex-direction: column;
  }
}

.btn-primary,
.wp-block-button__link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5rem;
  width: fit-content;
  font-size: $regular;
  line-height: normal;
  font-weight: 500;
  color: white;
  background-color: $color-primary;
  padding: .4rem 1rem;
  border-radius: $rounded;
  transition: all .2s linear;
  cursor: pointer;

  &:hover,
  .reverse {
    background-color: $color-secondary;
    color: white;
  }

  &.reverse {
    &:hover {
      background-color: $color-primary;
      color: white;
    }
  }

  &.white {
    background-color: white;
    color: $color-primary;

    &:hover {
      background-color: $color-gray;
      color: $color-primary;
    }
  }
  
  &.gray {
    background-color: $color-gray;
    color: $color-primary;

    &:hover {
      background-color: $color-primary;
      color: $color-gray;
    }
  }

  &.big {
    padding: 1rem;
  }

  i {
    font-size: inherit;
    color: inherit;
  }

  @media (max-width:576px) {
    &:not(button[type="submit"]) {
      width: 100% !important;
    }
  }
}

.btn-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  gap: .5rem;
  padding: .3rem .7rem;
  font-size: $small;
  line-height: normal;
  color: white;
  background-color: $color-primary;
  transition: all .2s linear;
  border-radius: $rounded;

  &:hover {
    background-color: $color-secondary; 
  }

  i {
    font-size: inherit;
    color: inherit;
  }

  &.pinned {
    width: 2.4rem;
    min-width: 2.4rem;
    height: 2.4rem;
    min-height: 2.4rem;

    svg {
      width: 1.9rem;
      height: auto;
    }
  }
}

.btn-secondary {
  color: $color-primary;
  font-size: $regular;
  line-height: normal;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: $xxsmall;
  width: fit-content;
  transition: all .2s linear;

  &::before {
    content: url('../images/arrow.svg');
    width: $xsmall;
    height: auto;
  }

  &:hover {
    margin-left: $xxsmall;
  }

  &.back,
  &.back-btn {
    gap: .5rem;

    span {
      opacity: 0;
      transition: all .2s linear;
    }

    &::before {
      content: url('../images/arrow-back.svg');
      width: 1.3rem;
    }

    &:hover {
      margin-left: 0;
      color: $color-primary;

      span {
        opacity: 1;
      }
    }
  }

  &.white {
    color: white;

    &::before {
      content: url('../images/arrow-white.svg');
    }
  }
}


.tag {
  padding: .2rem .8rem;
  border-radius: $rounded;
  font-size: $regular;
  line-height: $text-base-line-height;
  color: $color-primary;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
}

input[type="search"] {
  padding: .8rem 1.2rem;
  background-color: white;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

#no-page-header {
  height: $headerHeight;

@media (max-width:1199px) {
  height: $headerTab;
}

@media(max-width: 767px) {
  height: $headerPhone;
}
}

#load-more-button {
  margin: 3.5rem auto;
}

.container {

  #post-content {
    width: 100%;
		max-width: 66%;
		margin-inline: auto;
    margin-bottom: 7rem;

    p {
      margin-bottom: $gap;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .wp-block-columns {
      width: calc(117% + 1em);
      transform: translateX(-8.5%);
    }

		@media (max-width:1199px) {
			max-width: 83%;
		}

		@media (max-width:767px) {
			max-width: 100%;

      .wp-block-columns {
        width: 100%;
        transform: translateX(0);
      }
		}

    img {
      border-radius: $rounded;
    }

    blockquote {
      margin-top: 2.8rem;
      margin-bottom: 2.8rem;
      margin-inline: auto;
      width: 100%;
      max-width: 85%;
      padding: 0;
      border: none;

      p {
        font-size: $large;
        line-height: normal;
        font-weight: 400;
        color: $color-primary;
      }

      @media (max-width:767px) {
        max-width: 90%;
      }
    }
  }
}

/* Keyframe for fade-in */
@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

/* Keyframe for fade-out */
@keyframes fadeOut {
  from {
      opacity: 1;
  }
  to {
      opacity: 0;
  }
}

#no-posts-message, .post-container {
  transition: opacity 0.5s ease-in-out;
}

.show {
  display: block;
  animation: fadeIn 0.5s forwards;
}

.hidden {
  display: none;
  animation: fadeOut 0.5s forwards;
}