//
// Font Faces Styles
// @help: url('../webfonts/xxx)
// --------------------------------------------------

//Cervo Neue
@font-face {
  font-family: 'Cervo Neue';
  src: url('../webfonts/cervo-neue/CervoNeue-ExtraBoldNeue.woff2') format('woff2'),
      url('../webfonts/cervo-neue/CervoNeue-ExtraBoldNeue.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cervo Neue';
  src: url('../webfonts/cervo-neue/CervoNeue-BlackNeueItalic.woff2') format('woff2'),
      url('../webfonts/cervo-neue/CervoNeue-BlackNeueItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Cervo Neue';
  src: url('../webfonts/cervo-neue/CervoNeue-LightNeueItalic.woff2') format('woff2'),
      url('../webfonts/cervo-neue/CervoNeue-LightNeueItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Cervo Neue';
  src: url('../webfonts/cervo-neue/CervoNeue-BlackNeue.woff2') format('woff2'),
      url('../webfonts/cervo-neue/CervoNeue-BlackNeue.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cervo Neue';
  src: url('../webfonts/cervo-neue/CervoNeue-MediumNeue.woff2') format('woff2'),
      url('../webfonts/cervo-neue/CervoNeue-MediumNeue.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cervo Neue';
  src: url('../webfonts/cervo-neue/CervoNeue-BoldNeueItalic.woff2') format('woff2'),
      url('../webfonts/cervo-neue/CervoNeue-BoldNeueItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Cervo Neue';
  src: url('../webfonts/cervo-neue/CervoNeue-ExtraBoldNeueItalic.woff2') format('woff2'),
      url('../webfonts/cervo-neue/CervoNeue-ExtraBoldNeueItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Cervo Neue';
  src: url('../webfonts/cervo-neue/CervoNeue-ExtraLightNeueItalic.woff2') format('woff2'),
      url('../webfonts/cervo-neue/CervoNeue-ExtraLightNeueItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Cervo Neue';
  src: url('../webfonts/cervo-neue/CervoNeue-BoldNeue.woff2') format('woff2'),
      url('../webfonts/cervo-neue/CervoNeue-BoldNeue.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cervo Neue ExtraLight Neue';
  src: url('../webfonts/cervo-neue/CervoNeue-ExtraLightNeue.woff2') format('woff2'),
      url('../webfonts/cervo-neue/CervoNeue-ExtraLightNeue.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cervo Neue';
  src: url('../webfonts/cervo-neue/CervoNeue-LightNeue.woff2') format('woff2'),
      url('../webfonts/cervo-neue/CervoNeue-LightNeue.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cervo Neue';
  src: url('../webfonts/cervo-neue/CervoNeue-SemiBoldNeueItalic.woff2') format('woff2'),
      url('../webfonts/cervo-neue/CervoNeue-SemiBoldNeueItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Cervo Neue';
  src: url('../webfonts/cervo-neue/CervoNeue-ThinNeue.woff2') format('woff2'),
      url('../webfonts/cervo-neue/CervoNeue-ThinNeue.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cervo Neue';
  src: url('../webfonts/cervo-neue/CervoNeue-ThinNeueItalic.woff2') format('woff2'),
      url('../webfonts/cervo-neue/CervoNeue-ThinNeueItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Cervo Neue';
  src: url('../webfonts/cervo-neue/CervoNeue-RegularNeue.woff2') format('woff2'),
      url('../webfonts/cervo-neue/CervoNeue-RegularNeue.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cervo Neue';
  src: url('../webfonts/cervo-neue/CervoNeue-RegularNeueItalic.woff2') format('woff2'),
      url('../webfonts/cervo-neue/CervoNeue-RegularNeueItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Cervo Neue';
  src: url('../webfonts/cervo-neue/CervoNeue-SemiBoldNeue.woff2') format('woff2'),
      url('../webfonts/cervo-neue/CervoNeue-SemiBoldNeue.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cervo Neue';
  src: url('../webfonts/cervo-neue/CervoNeue-MediumNeueItalic.woff2') format('woff2'),
      url('../webfonts/cervo-neue/CervoNeue-MediumNeueItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}


//Font Awesome
@font-face {
  font-family: "Font Awesome 6 Brands";
  src: url("../webfonts/fontawesome/fa-brands-400.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-brands-400.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 6 Duotone";
  src: url("../webfonts/fontawesome/fa-duotone-900.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-duotone-900.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 6 Light";
  src: url("../webfonts/fontawesome/fa-light-300.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-light-300.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 6 Regular";
  src: url("../webfonts/fontawesome/fa-regular-400.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-regular-400.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 6 Sharp";
  src: url("../webfonts/fontawesome/fa-sharp-900.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-sharp-900.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 6 Solid";
  src: url("../webfonts/fontawesome/fa-solid-900.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-solid-900.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 6 Thin";
  src: url("../webfonts/fontawesome/fa-thin-100.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-thin-100.ttf") format("truetype");
}