@import "../../assets/styles/scss/variables";

section#related-news {
  margin: 7rem 0;

  @media (max-width:991px) {
    margin: 4rem 0;
  }

  .grid {
    grid-template-columns: 23rem auto;
    gap: 3rem;

    .related-news.swiper {
      width: 100%;

      .swiper-pagination {
        padding: 0 .5rem;
      }
    }

    @media (max-width:1441px) {
      grid-template-columns: 15rem auto;
    }

    @media (max-width:991px) {
      display: grid;
      grid-template-columns: 1fr;
      gap: 2rem;
    }
  }

  .titles {

    .btn-secondary {
      margin-top: $gap;
      display: none;
    }
  }

  .swiper-pagination {
    padding-left: .5rem;
  }

  .swiper-slide {
    order: 2;
    padding: .5rem;
    &.is-pinned {
      order: 1;

      .btn-icon.pinned {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }
    }

    .post-container {
      height: 100%;
    }
  }
}

body.page-template-template-homepage {

  section#related-news {
    margin-top: 9rem;
  }
  .titles {

    .btn-secondary {
      display: flex !important;
    }
  }
}