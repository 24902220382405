@import "../../assets/styles/scss/variables";

.section-infos {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 7rem;

  .left {
    img {
      border-radius: 0 $rounded $rounded 0;
      object-fit: cover;
      height: auto;
      transition: all 0.3s linear;
    }
  }

  .right {
    padding: 1.44rem 30% 2.11rem 5.55rem;

    h2 {
      padding: 0 0 1.08rem;
    }
  }

  &.container {
    display: flex;

    .right {
      padding: 0;
      width: 50%;

      h2 {
        padding: 0 0 1.08rem;
      }
    }
  }

  @media (max-width: 1399px) {
    .right {
      padding: 0 2.5rem 0 2.5rem;
    }
  }

  @media (max-width: 1199px) {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .right {
      padding: 0;
      width: 100%;
      max-width: 85vw;
      display: flex;
      flex-direction: column;
      margin: 0 auto;
    }

    .left {
      img {
        border-radius: 0;
      }
    }
  }
}