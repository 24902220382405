@import "../../assets/styles/scss/variables";

.bloc-majuscules {
  padding: 1.1rem .8rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: .5rem;
  color: black;

  img {
    width: 100%;
  }

  .btn-icon {
    padding: 0;
    background-color: transparent;
    color: $color-primary;
    border-radius: 0;
  }
}

a.bloc-majuscules {
  &:hover {
    color: white;
    background-color: $color-primary;

    .btn-icon {
      color: inherit;
    }
  }
}
