@import "../../assets/styles/scss/variables";

section#quote {
  display: flex;
  align-items: flex-start;
  gap: $gap;
  margin: 7rem 0;

  @media (max-width:991px) {
    margin: 4rem 0;
  }

  blockquote {
    width: 58%;
    margin: 0;
    padding: 0;
    border: 0;

    @media (max-width:1199px) {
      width: 66%;
    }

    @media (max-width:991px) {
      width: 83%;
    }

    @media (max-width:767px) {
      width: 100%;
    }
  }

  svg {
    width: 2.9rem;
    height: auto;
    margin-top: .8rem;
  }

  p.massive {
    font-style: italic;
    color: $color-primary;
  }
}